<template>
  <div>

   <div class="fens-header">
  <van-nav-bar
  title="粉丝管理"
  left-arrow
  :fixed="true"
@click-left="Close"
/></div>

    <!-- 导航 -->
   <div  class="wrap" style="margin:46px 0;">
   <van-tabs swipeable @change="onChange" :active="active" offset-top="46px"  :sticky="true">
   <van-tab title="全部">
    <div>
       <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <div class="fens-main"   v-for="(item,index) in fansData" :key="index" >
      <div> 
      <van-cell  ><van-col span="4" @click="showInfo(item)">
        <img :src="item.headImgUrl"  style="width:50px;height:50px;border-radius: 50%;" ></van-col>
      <van-col span="16"  >
       <div class="box1 flex-row" >
                  <span :class="item.status=='0'?'fens-word4  fens-box2 flex-col':'fens-word4  fens-box2-1 flex-col'">{{item.status=='0'?'有效':'无效'}}</span>
                  <span class="txt2" >{{item.nickName}}</span>
                  <img 
                    class="icon4"
                    referrerpolicy="no-referrer"
                    src="img/phone.png" 
                     v-show="item.phone!=null?true:false"
                    @click="copyWaybillNo('#copy-content')"  :data-clipboard-target="'#txt'+index" :id="'copy-content'"
                  />
                </div>
                   <div class="box1 flex-row" @click="showInfo(item)">
 
                   <span class="txt3" :id="'txt'+index"  >{{item.phone}}</span> <span class="word5">{{item.followTime}}</span>
                </div> 
      <div></div>
      </van-col>
      <van-col span="4">
         <!-- <div class="fens-mod3 flex-col"><span class="fens-word6" >联系TA</span></div> -->
          <a  v-if="item.status=='0'"  :href="'tel:' + item.phone">联系TA</a>
          <a  v-else href="javascript:;" @click="showInfo(item)" style="border:1px solid #999; color:#999;">联系TA</a>
      </van-col></van-cell>
      </div>

      
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div @click="showInfo(item)">
     <van-cell> <van-col span="6"  offset="4"><span style=" 
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">粉丝&emsp;{{item.fansNum}}</span>
      </van-col>
        <van-col  span="1" offset="2" ><div style=".vertical{
width:0;
height:30px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="6" offset="4"><span style="
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">有效粉丝&emsp;{{item.effectiveFansNum}}</span>
      </van-col></van-cell>
      </div>
    </div>
         </van-list>
    </div>
   </van-tab>

   <van-tab title="直邀">
    <div>
       <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <div class="fens-main"   v-for="(item,index) in fansData" :key="index" >
      <div> 
      <van-cell  ><van-col span="4" @click="showInfo(item)">
        <img :src="item.headImgUrl"  style="width:50px;height:50px;border-radius: 50%;" ></van-col>
      <van-col span="16" >
       <div class="box1 flex-row">
                  <span :class="item.status=='0'?'fens-word4  fens-box2 flex-col':'fens-word4  fens-box2-1 flex-col'">{{item.status=='0'?'有效':'无效'}}</span>
                  <span class="txt2">{{item.nickName}}</span>
                  <img   v-show="item.phone!=null?true:false"
                    @click="copyWaybillNo('#copy-content')"  :data-clipboard-target="'#txt'+index" :id="'copy-content'"
                    class="icon4"
                    referrerpolicy="no-referrer"
                   src="img/phone.png"
                  />
                </div>
                   <div class="box1 flex-row"   @click="showInfo(item)" >
 
                   <span class="txt3" :id="'txt'+index">{{item.phone}}</span> <span class="word5">{{item.followTime}}</span>
                </div> 
      <div></div>
      </van-col>
      <van-col span="4">
         <!-- <div class="fens-mod3 flex-col" ><span class="fens-word6" @click="show(index)">联系TA</span></div> -->
         <a  v-if="item.status=='0'"  :href="'tel:' + item.phone">联系TA</a>
          <a  v-else href="javascript:;" @click="showInfo(item)" style="border:1px solid #999; color:#999;">联系TA</a>

      </van-col></van-cell>
      </div>

      
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div @click="showInfo(item)">
     <van-cell> <van-col span="6"  offset="4" style="
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;"><span>粉丝&emsp;{{item.fansNum}}</span>
      </van-col>
              <van-col  span="1" offset="2" ><div style=".vertical{
width:0;
height:30px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="6" offset="4"><span style=" 
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">有效粉丝&emsp;{{item.effectiveFansNum}}</span>
      </van-col></van-cell>
      </div>
    </div>
         </van-list>
    </div>
   </van-tab>


<van-tab title="推荐">
    <div>
       <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <div class="fens-main"   v-for="(item,index) in fansData" :key="index" >
      <div> 
      <van-cell  ><van-col span="4" @click="showInfo(item)" >
        <img :src="item.headImgUrl"    style="width:50px;height:50px;border-radius: 50%;" ></van-col>
       <van-col span="16" >
       <div class="box1 flex-row">
                  <span :class="item.status=='0'?'fens-word4  fens-box2 flex-col':'fens-word4  fens-box2-1 flex-col'">{{item.status=='0'?'有效':'无效'}}</span>
                  <span class="txt2">{{item.nickName}}</span>
                  <img  
                    class="icon4"
                    referrerpolicy="no-referrer"
                    src="img/phone.png"
                     v-show="item.phone!=null?true:false"
                    @click="copyWaybillNo('#copy-content')"  :data-clipboard-target="'#txt'+index" :id="'copy-content'"
                  />
                </div>
                   <div class="box1 flex-row"  @click="showInfo(item)" >
 
                   <span class="txt3" :id="'txt'+index" >{{item.phone}}</span> <span class="word5">{{item.followTime}}</span>
                </div> 
      <div></div>
      </van-col>
      <van-col span="4">
         <!-- <div class="fens-mod3 flex-col"><span class="fens-word6" @click="show(index)">联系TA</span></div> -->
      </van-col></van-cell>
      </div>

      
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div @click="showInfo(item)">
     <van-cell> <van-col span="6"  offset="4"><span style="
        font-size: 12px;
        font-family: PingFangSC-Regular;
        line-height: 17px;">粉丝&emsp;{{item.fansNum}}</span>
      </van-col>
              <van-col  span="1" offset="2" ><div style=".vertical{
        width:0;
        height:30px;
        border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="6" offset="4"><span style="
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">有效粉丝&emsp;{{item.effectiveFansNum}}</span>
      </van-col></van-cell>
      </div>
    </div>
         </van-list>
    </div>
   </van-tab>

<van-tab title="潜在">
    <div>
       <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <div class="fens-main"   v-for="(item,index) in fansData" :key="index" >
      <div> 
      <van-cell  ><van-col span="4" @click="showInfo(item)">
        <img :src="item.headImgUrl"  style="width:50px;height:50px;border-radius: 50%;"></van-col>
      <van-col span="16" >
       <div class="box1 flex-row">
                  <span :class="item.status=='0'?'fens-word4  fens-box2 flex-col':'fens-word4  fens-box2-1 flex-col'">{{item.status=='0'?'有效':'无效'}}</span>
                  <span class="txt2">{{item.nickName}}</span>
                  <img  
                    class="icon4"
                    referrerpolicy="no-referrer"
                   src="img/phone.png"
                    v-show="item.phone!=null?true:false"
                    @click="copyWaybillNo('#copy-content')"  :data-clipboard-target="'#txt'+index" :id="'copy-content'"
                  />
                </div>
                   <div class="box1 flex-row" @click="showInfo(item)">
 
                   <span class="txt3" :id="'txt'+index" >{{item.phone}}</span> <span class="word5">{{item.followTime}}</span>
                </div> 
      <div></div>
      </van-col>
      <van-col span="4">
         <a  v-if="item.status=='0'"  :href="'tel:' + item.phone">联系TA</a>
          <a  v-else href="javascript:;" @click="showInfo(item)" style="border:1px solid #999; color:#999;">联系TA</a>
          <!-- <a   :href="'tel:' + item.phone">联系TA</a> -->
         <!-- <div class="fens-mod3 flex-col"><span class="fens-word6" @click="show(index)">联系TA</span></div> -->
      </van-col></van-cell>
      </div>

      
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div @click="showInfo(item)">
     <van-cell> <van-col span="6"  offset="4"><span style="
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">粉丝 &emsp;{{item.fansNum}}</span>
      </van-col>
              <van-col  span="1" offset="2" ><div style=".vertical{
width:0;
height:30px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="6" offset="4"><span style="
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">有效粉丝 &emsp;{{item.effectiveFansNum}}</span>
      </van-col></van-cell>
      </div>
    </div>
         </van-list>
    </div>
   </van-tab>
   </van-tabs>
    </div>
      <!-- 订单信息 -->

 <van-dialog
  use-slot
  v-model="isShow"
  theme="round-button"
  :showConfirmButton="false"
  title="联系他"
  :overlay="true"
  :close-on-click-overlay="true"
 >
   <div v-if="fansData.length>0">
       <div style="text-align: center;">手机号:&nbsp;<span id="phone">{{fansData[index].phone}}</span><img src="img/phone.png"  
        v-show="fansData[index].phone!=null?true:false"
                    @click="copyWaybillNo('#copy-content')"  :data-clipboard-target="'#phone'" :id="'copy-content'"/></div>
      <!-- <div style="text-align: center;"><span>微信号:&nbsp;未绑定微信号</span></div>-->
   </div>
</van-dialog>
  </div>
</template>
<script>
import {getObj} from '@/api/fans/fans'
import { Toast } from "vant";
import Clipboard from 'clipboard';
export default {
  data() {
    return {
    active:-1,
    index:0,
    isShow:false,
    fansData:[],
    value1: 0,
    value2: 'a',
    loading: false,
    finished: true,
    page: 1,//请求第几页
    pageSize: 30,//每页请求的数量
    total: 0,//总共的数据条数
    type:'0',
    };
  },
    mounted() {   
       if(this.$route.query.session!=null)
      {
        this.session=this.$route.query.session
        this.$store.commit('setSession', this.session)
      }
      if(this.$route.query.type!=null)
      {
        this.type=this.$route.query.type
      }
      if(this.$route.query.currentIndex!=null)
      {
         this.getDataList(this.$route.query.currentIndex)
         this.active=parseInt(this.$route.query.currentIndex)
      }else{
         this.getDataList(this.$store.state.fansActive)
         this.active=this.$store.state.fansActive
      }
     }, 
  created() {},
  methods:{
       //滚动加载时触发，list组件定义的方法
    onLoad() {
      this.page++;
      this.getDataList(this.active);
    },
    getDataList(i) {
      let params = {
        current: this.page, 
        size: this.pageSize 
      };
      let params1 ={}
        if(i!=0)
        {
             params1 ={'type':i}
        }
        const object2 = Object.assign(params, params1);
        getObj(object2).then(res => {
        let rows = res.data.data.records; //请求返回当页的列表
       
         // 将新数据与老数据进行合并
         if(res.data.code===0)
         {
            this.fansData = this.fansData.concat(rows);
         }
        //alert(this.fansData.length)
       //如果列表数据条数>=总条数，不再触发滚动加载
        this.loading = false; this.finished = false;
        //alert(JSON.stringify(this.total))
        if (rows == null || rows.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }
        if (this.page>=this.total) {
          this.finished = true;
        }
      });
    },
    onChange(event)
    {
      this.fansData=[]
      this.active=event;
      this.getDataList(event)
    },
    show(index)
    {
      this.isShow = true
      this.index=index
    },
      // 返回原生
    Close()
    {
      if(this.type=='1')
      {
        this.$router.go(-1)
      }
    else{
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isIOS)
      {
          window.webkit.messageHandlers.close.postMessage('up');   
      }
      if (isAndroid) {
        window.android.close(); 
       } 
    }
     this.$store.commit('setfansActive',0)
      },
          copyWaybillNo(id) {
        let clipboard = new Clipboard(id);
        this.$nextTick(() => {
          clipboard.on('success', function () {
            Toast("复制成功");
            clipboard.destroy();
            
          });
          clipboard.on('error', function () {
            console.log('您的浏览器不支持点击复制');
            clipboard.destroy();
          });
        });
      },
      showInfo(item)
      {
        //alert(JSON.stringify(item))
        if(!(item.status=='0')) { 

          this.$toast.fail('粉丝失效')
          return
        }
       this.$store.commit('setfansActive',this.active)
       this.$router.push({name:'fansinfo',query: {id:item.id}})
      },

  },
  components: {},
};
</script>
<style scoped>
a{ 
  display: block;
  width: 50px;
  height: 22px;
  border-radius: 33px;
  border: 1px solid #FF5200;
  color: #FF5200;
  font-size: 12px;
  
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fens-content
{
 margin-top:50px
}
 .fens-main{
  margin-left: 1%;
  margin-right: 1%;
  background-color:white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width:98%;
  height: 130px;
  margin-top:10px;
  }
  .fens-main2 {
  width: 98%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  align-self: center;
}
.fens-mod3 {
  border-radius: 11px;
  border: 1px solid rgba(255, 82, 0, 1);
  background-color: rgba(255, 255, 255, 1);

  justify-content: center;
  align-items: center;
}

.fens-word6 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 11px;
  font-family: PingFangSC-Regular;
  line-height: 12px;
  /*height: 16px;*/
  text-align: center;
}
.fens-box2 {
  height: 14px;
  border-radius: 7px;
  border: 1px solid rgba(255, 82, 0, 1);
  background-color: rgba(255, 82, 0, 1);
width:30px;
  justify-content: center;
  align-items: center;
}
.fens-box2-1 {
  height: 14px;
  border-radius: 7px;
  border: 1px solid rgb(180, 178, 177);
  background-color: rgb(187, 183, 181);
width:30px;
  justify-content: center;
  align-items: center;
}
.fens-word4 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  font-family: PingFangSC-Regular;
  line-height: 11px;
  text-align: center;
}
.txt3
{
 /* display: block;*/
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
}
.word5
{
 /* display: block;*/
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
}
  .van-tabs--line .van-tabs__wrap {
    height: 44px;
    position: fixed;
    width: 100%;
}
.van-tabs__content
{
  padding-top:50px;
}

.wrap::-webkit-scrollbar{ 
  display: none;
}
</style>

